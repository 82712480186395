<template>

  <section class="px-4 py-20 md:py-32 md:px-10 lg:px-36 space-y-6">
    <div
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-duration="800"
      data-sal-easing="ease-out-bounce"
    >
<!--      <p class="text-lg font-medium text-gray-600">-->
<!--        {{ $t("productSection.smallTitle") }}-->
<!--      </p>-->
      <p class="text-4xl font-black text-white">
        {{ $t("productSection.bigTitle") }}
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <template v-for="(el, idx) in products" :key="idx">
        <div
            class="product-card-wrapper"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="800"
            data-sal-easing="ease-out-bounce"
            @click="onClick(idx)"
        >
          <div class="flex justify-center">
            <img
                class="rounded-lg object-cover rounded-b-none h-310"
                :src="getImg(el.imgMin)"
                alt="product image"
                loading="lazy"
            />
          </div>
          <div class="py-4 space-y-2 flex justify-center">
            <h1 class="text-2xl font-bold text-white">{{ el.title }}</h1>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import {products} from "../../data/products";
import sal from "sal.js";
export default {
  name: "Product",
  data() {
    return {
      products,
    }
  },
  methods: {
    getImg(img) {
      return require('./../../assets/img/products/' + img);
    },
    onClick(idx) {
      this.$router.push({ name: "ProductPage", params: {id: idx }});
    }
  },
  mounted() {
    sal();
  },
};
</script>

<style scoped>
img {
  height: 260px;
  width: 310px;
}
</style>
<style lang="postcss">
.product-card-wrapper {
  @apply flex flex-col
          h-full
          max-w-lg
          mx-auto
          bg-gray-800
          shadow
          hover:shadow-xl
          cursor-pointer
          duration-700
          rounded-lg;
}

.product-button {
  @apply flex
              px-3
              py-1
              bg-gray-400
              items-center
              font-semibold
              rounded-full
              text-white
              hover:bg-gray-500
              focus:ring-4 focus:ring-gray-300;
}
</style>
